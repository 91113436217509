import { StyledDialog, StylesCreateInternalOJT } from './styled';
import { ReceivedProps } from '../type';
import useCreateInternalOJT, { Props } from './hook';
import { Button } from '@mui/material';
import uploadSimple from 'assets/icons/UploadSimple.svg';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import DialogContent from '@mui/material/DialogContent';
import DeleteIcon from '@mui/icons-material/Delete';

const CreateInternalOJTLayout = ({
    formik,
    navigate,
    handleFileUpload,
    handleViewFile,
    currentData,
    fileData,
    setFileData,
    id,
    typeFile,
    handleDeleteFile,
    showErrorFileSize,
    errorTypeFile
}: Props) => {
    const renderContentPreview = () => {
        if (!fileData || !typeFile) return;
        if (typeFile?.includes("pdf")) {
            return <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                <Viewer
                    fileUrl={fileData}
                />
            </Worker>
        } else if(typeFile?.includes("image")) {
            return <img src={fileData} alt='preview-image-ojt'></img>
        }
    }
    return (
        <StylesCreateInternalOJT>
            {fileData && (
                <StyledDialog
                    onClose={() => setFileData(null)}
                    sx={{
                        width: '100%',
                        '.MuiPaper-root': {
                            width: typeFile?.includes("pdf") ? "100%" : "unset",
                            height: typeFile?.includes("pdf") ? "100%" : "unset",
                            margin: '0px',
                        },
                       
                        '.rpv-core__inner-container': {
                            paddingBottom: '30px !important',
                        },
                    }}
                    open={!!fileData}
                >
                    <DialogContent>
                        {
                            renderContentPreview()
                        }
                    </DialogContent>
                </StyledDialog>
            )}
            <p className="title">{id ? "社内OJT編集" : "社内OJT新規登録"}</p>
            <form onSubmit={formik.handleSubmit}>
                <div className="flex flex-col mb-[14px]">
                    <label>タイトル</label>
                    <input
                        className="max-w-[342px]"
                        name="subject"
                        value={formik.values.subject}
                        onChange={(e) =>
                            formik.setFieldValue('subject', e.target.value)
                        }
                    />
                    {formik.touched.subject && formik.errors.subject && (
                        <span className="error">{formik.errors.subject}</span>
                    )}
                    {!formik.values.subject.trim() && formik.touched.subject && !formik.errors.subject && (
                        <span className="error">必須項目です</span>
                    )}
                </div>
                <div className="flex flex-col mb-[14px]">
                    <label>内容</label>
                    <textarea
                        name="content"
                        value={formik.values.content}
                        onChange={(e) =>
                            formik.setFieldValue('content', e.target.value)
                        }
                    />
                    {formik.touched.content && formik.errors.content && (
                        <span className="error">{formik.errors.content}</span>
                    )}
                    {!formik.values.content.trim() && formik.touched.content && !formik.errors.content && (
                        <span className="error">必須項目です</span>
                    )}
                </div>
                <div className="flex flex-col mb-[14px]">
                    <label>ファイル</label>
                    <Button
                        component="label"
                        className="uploadImageFileBtn"
                        style={{
                            width: 'fit-content',
                            backgroundColor: '#215493',
                            color: '#FFFFFF',
                            fontWeight: '500',
                            fontSize: '16px',
                            lineHeight: '24px',
                            padding: '10px 18px',
                            height: '36px',
                            borderRadius: '6px',
                        }}
                    >
                        <img
                            src={uploadSimple}
                            alt="upload icon"
                            style={{ marginRight: '7px' }}
                        />
                        <span>ファイルアップロード</span>
                        <input
                            type="file"
                            name="files"
                            hidden
                            style={{
                                display: 'none',
                            }}
                            onChange={handleFileUpload}
                            accept="image/png, image/gif, image/jpeg, image/webp, image/jpg, application/pdf"
                            multiple
                            onClick={(e) => (e.target as HTMLInputElement).value = ""}
                        />
                    </Button>
                    {formik?.values?.files && Array.from(formik?.values?.files)?.map((item: any, index: number) => (
                        <div key={index} className='flex gap-x-[4px]'>
                            <p className="mb-0">{item?.name}</p>
                            <p className="text-[#195192] text-[14px] font-[500] cursor-pointer underline mb-0" onClick={() => { handleViewFile(item) }}>プレビュー</p>
                            <DeleteIcon className='text-[#d11a2a] cursor-pointer' onClick={() => {handleDeleteFile(item?.signed_id, Array.from(formik?.values?.files), item?.lastModified, index)}}></DeleteIcon>
                        </div>
                    ))}

                    <span className="error">{showErrorFileSize}</span>
                    <span className="error">{errorTypeFile}</span>
                    {Array.from(formik?.values?.files)?.length <= 0 && formik.touched.files && <span className="error">必須項目です</span>}
                </div>
                <div className="mt-10">
                    <Button
                        onClick={() => navigate('/internal-ojt')}
                        sx={{
                            padding: '6px 18px',
                            height: '44.5px',
                            width: '166.5px',
                            borderColor: 'rgba(33, 84, 147, 0.30)',
                            color: '#215493',
                            borderRadius: '8px',
                            marginRight: '4px',
                        }}
                        variant="outlined"
                    >
                        戻る
                    </Button>
                    <Button
                        type="submit"
                        // onClick={handleCreateVehicleInspection}
                        sx={{
                            padding: '6px 18px',
                            width: '166.5px',
                            marginLeft: '4px',
                            backgroundColor: '#215493',
                            borderColor: '#215493',
                            color: '#fff',
                            height: '44.5px',
                            borderRadius: '8px',
                        }}
                        variant="contained"
                    >
                        登録
                    </Button>
                </div>
            </form>
        </StylesCreateInternalOJT>
    );
};

const CreateInternalOJT = (props: ReceivedProps) => {
    return <CreateInternalOJTLayout {...useCreateInternalOJT(props)} />;
};

export default CreateInternalOJT;
