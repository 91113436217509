import { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { getFilePreview } from 'utils/helper/file';
import projectsService from 'services/projectsService';
import deleteIcon from 'assets/icons/deleteIcon.svg';
import { PastImagesContainer } from './styled';

import { notification, Carousel } from 'antd';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ImageService from 'services/imageService';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
interface IFile {
  image_path?: string;
  signed_id?: string;
  file?: File;
}

const Input = styled('input')({
  display: 'none !important',
});

export const PastImages = ({ projectID, className = '', onBack = () => { } }) => {
  const [errorQuantityFiles, setErrorQuantityFiles] = useState(false);
  const [fileUpload, setFileUpload] = useState<any[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedImage, setSelectedImage] = useState<IFile>();
  const [isLoading, setIsLoading] = useState(false);
  const [hasUploadedImagesDeleted, setHasUploadedImagesDeleted] = useState(false);
  const [disableSubmit, setDisabledSubmit] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [errorTypeFile, setErrorTypeFile] = useState(false);
  const [loadingConvert, setLoadingConvert] = useState(false);
  const [listFileConvert, setListFileConvert] = useState<string[]>([]);
  const [isHiddenListConvert, setIsHiddentListConvert] = useState(false);

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type: string, message: string) => {
    api[type]({
      message: message,
      description:
        '',
    });
  };

  const getProjectDetail = async (id) => {
    try {
      const response = await projectsService.getProject(id);
      if (response !== null && response !== undefined) {
        setFileUpload(response?.project?.project_images || [])
      }

    } catch (err) {
      console.log('err', err);
    }
  };

  const convertImage = async (listFile) => {
    setLoadingConvert(true)
    try {
      let formData = new FormData();
      listFile?.forEach((file) => {
        if(!file.file) return;
        formData.append('images[]', file?.file);
      });
      const res = await ImageService.convertImage(formData)
      if (!(res as { converted_image_urls: string[] })?.converted_image_urls) return;
      const converted_image_urls = (res as { converted_image_urls: string[] })?.converted_image_urls;
      let listFileAfterConvert = converted_image_urls?.map(item => `${process.env.REACT_APP_DOMAIN_IMAGE}${item}`);
      setListFileConvert(listFileAfterConvert?.reverse());
      setLoadingConvert(false)
    } catch (error) {
      console.log("convert image error: ", error)
      setLoadingConvert(false)
    }
  }

  const handleUploadFile = (files) => {
    let current = fileUpload ? [...fileUpload] : [];
    let currentUpload = fileUpload ? [...fileUpload] : [];
    if (files.length > 0) {
      const fileArr = [...files];
      fileArr?.map((item) => {
        current.push({ file: item, type: 'upload', size: item?.size });
      })

      let currentFilterWrongType = current?.filter(item => !(item?.file?.type === 'image/jpeg' || item?.file?.type === 'image/jpg' || item?.file?.type === 'image/svg+xml' || item?.file?.type === 'image/png' || item?.file?.type === 'image/gif' || item?.file?.type === 'image/tiff' || (item?.file?.name?.includes('.heic') && item?.file?.type === '') || (item?.file?.name?.includes('.heif') && item?.file?.type === '')) && !item?.signed_id)
      let currentFilterValidType = current?.filter(item => (item?.file?.type === 'image/jpeg' || item?.file?.type === 'image/jpg' || item?.file?.type === 'image/svg+xml' || item?.file?.type === 'image/png' || item?.file?.type === 'image/gif' || item?.file?.type === 'image/tiff' || (item?.file?.name?.includes('.heic') && item?.file?.type === '') || (item?.file?.name?.includes('.heif') && item?.file?.type === '')) || item?.signed_id)
      setErrorTypeFile(currentFilterWrongType?.length > 0 ? true : false);

      if (current?.filter(item => item?.type !== 'destroy')?.length > 50) {
        setErrorQuantityFiles(current?.filter(item => item?.type !== 'destroy')?.length > 50 ? true : false)
        const arrRemoveFileUpload = current?.filter(item => item?.type != 'upload').concat(currentUpload?.filter(item => item?.type == 'upload'))
        setFileUpload(arrRemoveFileUpload);
        convertImage(arrRemoveFileUpload);
      } else {
        setFileUpload(currentFilterValidType);
        setErrorQuantityFiles(false);
        convertImage(currentFilterValidType);
      }
    }
    setDisabledSubmit(false);
  };

  const handleUpdateDeletedImage = () => {
    const promisesArray: Promise<unknown>[] = [];
    fileUpload.forEach((item) => {
      if (item.type == 'destroy' && !item?.file?.lastModified) {
        promisesArray.push(
          new Promise(async (resolve, reject) => {
            await projectsService.deletePastImage(
              projectID,
              item.signed_id,
            )
              .then(() => resolve(true))
              .catch((err) => reject(err));
          }),
        );
      }
    });
    return Promise.all(promisesArray);
  };

  const removeImage = (lastModified, index, isConvert = false) => {
    // remove for send file to BE
    let current = [...fileUpload];
    let res = current?.map(((item, index) => {
      return { ...item, ind: index }
    }))
    current = isConvert ? res.filter((item, idx) => idx !== index + fileUpload?.filter(item => item?.signed_id)?.length) : res.filter((item) => !(item?.file?.lastModified == lastModified && item?.ind == index));
    setFileUpload(current);
    setErrorQuantityFiles(false);
    setDisabledSubmit(false);

    // remove for preview file convert in FE
    let currentConvert = [...listFileConvert];
    currentConvert = currentConvert.filter((item, idx) => idx !== index);
    setListFileConvert(currentConvert)
  };

  const handleRemoveUploadedImage = (signed_id) => {
    setFileUpload((prev) => {
      const newFilesArray = [...prev];
      let target = newFilesArray.find(
        (item) => item.signed_id === signed_id,
      );
      const targetIndex = newFilesArray.findIndex(
        (item) => item.signed_id === signed_id,
      );
      target = { ...target, type: 'destroy' };
      newFilesArray[targetIndex] = target;
      return newFilesArray;
    });
    setErrorQuantityFiles(false);
    setDisabledSubmit(false);
    if (!hasUploadedImagesDeleted) {
      setHasUploadedImagesDeleted(true);
    }
  };

  const handleSubmit = async () => {
    let arrUpload = fileUpload?.filter(item => item?.file);
    if (!projectID) {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    let formData = new FormData();
    if (arrUpload?.length > 0) {
      arrUpload?.forEach((item) => {
        formData.append('images[]', item.file);
      });
    }
    if (hasUploadedImagesDeleted) {
      await handleUpdateDeletedImage();
      setIsLoading(false);
      setDisabledSubmit(true);
      if(arrUpload?.length <= 0) {
         openNotificationWithIcon('success', '画像を削除しました');
      }
    }
    if(arrUpload?.length > 0) {
      await projectsService.uploadPastImages(projectID, formData);
      openNotificationWithIcon('success', '画像登録が完了しました。');
      setIsLoading(false);
      setDisabledSubmit(true);
      setListFileConvert([]);
    }
    setIsLoading(false);
    getProjectDetail(projectID);
  }

  const SampleNextArrow = props => {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{ ...style, display: 'block', right: 0 }}
        onClick={onClick}
      >
        <ArrowForwardIosIcon className='text-black' />
      </div>
    )
  }
  
  const SamplePrevArrow = props => {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{ ...style, display: 'block', left: 0, zIndex: 1 }}
        onClick={onClick}
      >
        <ArrowBackIosIcon className='text-black' />
      </div>
    )
  }
  const settings = {
    nextArrow: <SampleNextArrow className='tesstt' />,
    prevArrow: <SamplePrevArrow />
  }
  
  useEffect(() => {
    if (projectID) {
      getProjectDetail(projectID)
    }
  }, [projectID])

  return <PastImagesContainer className={className}>
    {contextHolder}
    <Dialog
      onClose={() => { setSelectedImage({}); setOpenDialog(false); setSelectedImageIndex(0) }}
      sx={{
        '.MuiPaper-root': {
          width: "unset",
          height: "unset",
          margin: '0px',
        },

        '.rpv-core__inner-container': {
          paddingBottom: '30px !important',
        },
      }}
      open={openDialog}
    >
      <DialogTitle className='flex justify-end'>
        <span onClick={() => { setSelectedImage({}); setOpenDialog(false); setSelectedImageIndex(0) }}><CloseIcon /></span>
      </DialogTitle>
      <DialogContent>
        <Carousel initialSlide={selectedImageIndex} arrows {...settings}>
          {fileUpload &&
            fileUpload
              .map((item, idx) => {
                if (item?.type == 'destroy' || item?.file) return;
                return (
                  <div
                    className="imageWrapper"
                    key={`image-preview-${idx}`}
                  >
                    <img
                      src={
                        item?.image_path
                          ? item.image_path
                          : getFilePreview(
                            item?.file,
                          )
                      }
                      onClick={() => { setSelectedImage(item); setOpenDialog(true) }}
                      className='w-full h-full min-w-[531px]'
                    />
                  </div>
                );
              })}
          {!isHiddenListConvert && listFileConvert?.map((item, idx) => {
            return (
              <div
                className="imageWrapper max-h-[80vh]"
                key={`image-preview-${idx}`}
              >
                <img
                  src={
                    item
                  }
                  className='w-full h-full'
                />

              </div>
            );
          })}
        </Carousel>
      </DialogContent>
    </Dialog>
    <div className='flex'>
      <label
        htmlFor="contained-button-file"
        className="file-uploader-wrapper"
      >
        <Input
          accept="image/*, .heic, .heif"
          id="contained-button-file"
          multiple
          type="file"
          onChange={(e) =>
            handleUploadFile(e.target.files)
          }
          onClick={(e) => (e.target as HTMLInputElement).value = ""}
        />
        <div className="addFileBtn">
          <div className='addFileBtn-label w-full'>
            <p className='mb-0'>+</p>
            <p className='mb-0'> 画像アップロード</p>
          </div>
        </div>
      </label>
      <div className="imagesContainer">
      {!isHiddenListConvert && listFileConvert?.map((item, idx) => {
          return (
            <div
              className="imageWrapper"
              key={`image-preview-${idx}`}
            >
              <img
                src={
                  item
                }
                onClick={() => { setOpenDialog(true); setSelectedImageIndex(idx + fileUpload?.filter(item => item?.signed_id)?.length - fileUpload?.filter(item => item?.type == 'destroy')?.length)}}
                className='cursor-pointer'
              />
              <img
                className="deleteIcon"
                src={deleteIcon}
                onClick={() => {removeImage('0', idx, true)}}
              />
            </div>
          );
        })}
        {loadingConvert && <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />}
        {
          fileUpload?.map((item, idx) => {
            if (item?.type == 'destroy' || item?.file) return;
            return (
              <div
                className="imageWrapper"
                key={`image-preview-${idx}`}
              >
                <img
                  src={
                    item?.image_path
                      ? item.image_path
                      : getFilePreview(
                        item?.file,
                      )
                  }
                  onClick={() => { setSelectedImage(item); setOpenDialog(true); setSelectedImageIndex(idx <= 1 ? idx : idx - fileUpload?.filter(item => item?.type == 'destroy')?.length) }}
                  className='cursor-pointer'
                />
                <img
                  className="deleteIcon"
                  src={deleteIcon}
                  onClick={() => {
                    item.signed_id
                      ? handleRemoveUploadedImage(
                        item.signed_id
                      )
                      : removeImage(
                        item?.file?.lastModified, idx
                      )
                  }
                  }
                />
              </div>
            );
          })}
        
      </div>
    </div>
    {errorQuantityFiles && <p className='text-[#ED1E24] mt-[10px] mb-0'>一度にアップロード出来る画像は50枚までです。</p>}
    {errorTypeFile && <span className="error">ファイル形式が正しくありません。</span>}
    <div className='flex gap-x-[10px] mt-[10px]'>
      <button className='btn-back' onClick={onBack}>戻る</button>
      <button
        disabled={fileUpload?.length <= 0 || isLoading || disableSubmit}
        className={`btn-submit ${fileUpload?.length <= 0 || isLoading || disableSubmit ? 'opacity-70' : ''}`}
        onClick={handleSubmit}
      >
        登録
      </button>
    </div>
  </PastImagesContainer>
}
