import { useState, useEffect } from 'react';
import './userList.css';
import userService from 'services/userService';
import workersService from 'services/wokersService';
import LineFriendService from 'services/lineService';
import plus from 'assets/icons/Plus.png';
import 'react-dropdown/style.css';
import { convertObjectToDropdownOptions } from 'utils/helper/dropdownHelper';
import CreateNewUser from './components/create';
import UpdateUser from './components/update';
import DeleteUser from './components/delete';
import UserTable from './components/UserTable';
import UserSearch from './components/UserSearch';
import CustomPagination from 'components/CustomPagination';

const UserList = (props) => {
    const [userList, setUserList] = useState<any>(null);
    const [nameSearch, setNameSearch] = useState('');
    const [emailSearch, setEmailSearch] = useState('');
    const [selectedUser, setSelectedUser] = useState<any>(null);

    const [openCreateModal, setOpenCreateModal] = useState<any>(false);
    const [openUpdateModal, setOpenUpdateModal] = useState<any>(false);
    const [openDeleteModal, setOpenDeleteModal] = useState<any>(false);
    const [isOpenWorker, setIsOpenWorker] = useState(false);
    const [isOpenLineBotFriend, setIsOpenLineBotFriend] = useState(false);

    const [workerOptions, setWorkerOptions] = useState<any[]>([]);
    const [lineFriendsOptions, setLineFriendsOptions] = useState<any[]>([]);
    const [readOnly, setReadOnly] = useState(true);
    const [isOpenRole, setIsOpenRole] = useState(false);
    const [pageQueries, setPageQueries] = useState({
        page: 1,
        per_page: 20,
    });
    const [totalCount, setTotalCount] = useState(0);

    const getWorkerOptions = async () => {
        try {
            const response = await workersService.getListWorkers({include_workers_without_user: true});
            if (response) {
                setWorkerOptions(
                    convertObjectToDropdownOptions(response.workers),
                );
            }
        } catch (error) {
            // empty
        }
    };

    const getLineFriendOptions = async () => {
        try {
            const { line }: any = await LineFriendService.getLineFriends();
            setLineFriendsOptions(
                line?.map((item) => ({
                    label: item.display_name,
                    value: item.id,
                })),
            );
        } catch (error) {
            // empty
        }
    };

    useEffect(() => {
        getWorkerOptions();
        getLineFriendOptions();
    }, []);

    const search = (key, key2, inputArray) => {
        let arr: Array<any> = [];
        if (key !== '' && key2 === '') {
            for (let i = 0; i < inputArray.length; i++) {
                if (
                    inputArray[i]?.worker?.name
                        .toLowerCase()
                        .includes(key.toLowerCase())
                ) {
                    arr.push(inputArray[i]);
                }
            }
            return arr;
        }
        if (key === '' && key2 !== '') {
            for (let i = 0; i < inputArray.length; i++) {
                if (
                    inputArray[i]?.worker?.email
                        .toLowerCase()
                        .includes(key2.toLowerCase())
                ) {
                    arr.push(inputArray[i]);
                }
            }
            return arr;
        }
        if (key !== '' && key2 !== '') {
            for (let i = 0; i < inputArray.length; i++) {
                if (
                    inputArray[i]?.worker?.name
                        .toLowerCase()
                        .includes(key.toLowerCase()) &&
                    inputArray[i]?.worker?.email
                        .toLowerCase()
                        .includes(key2.toLowerCase())
                ) {
                    arr.push(inputArray[i]);
                }
            }
            return arr;
        }
    };

    const handleSearchName = () => {
        getListUser({ email: emailSearch?.trim(), worker_name: nameSearch?.trim() });
    };

    const handleOpenCreateModal = () => {
        setOpenCreateModal(true);
    };

    const handleOpenUpdateModal = (row) => {
        setOpenUpdateModal(true);
        setSelectedUser(row);
    };

    const handleOpenDeleteModal = (row) => {
        setOpenDeleteModal(true);
        setSelectedUser(row);
    };

    const getListUser = async (params = {}) => {
        try {
            const response = await userService.getListUser({...params});
            if (response !== null && response !== undefined) {
                setUserList(response.users);
                setTotalCount(response?.meta?.total_count)
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    useEffect(() => {
        getListUser(pageQueries);
    }, []);

    useEffect(() => {
        if (!openUpdateModal) {
            setSelectedUser(null);
        }
    }, [openUpdateModal]);

    useEffect(() => {
        if (!openDeleteModal) {
            setSelectedUser(null);
        }
    }, [openDeleteModal]);

    return (
        <div className="container-users d-flex flex-column">
            <div className="users-content d-flex flex-column">
                <div className="create-button d-flex flex-row">
                    <button
                        className="create-user-button d-flex flex-row"
                        onClick={handleOpenCreateModal}
                    >
                        <img width={'16px'} height={'16px'} src={plus}></img>
                        新規作成
                    </button>
                </div>

                <UserSearch
                    onSearchName={(e) => setNameSearch(e.target.value)}
                    onSearchEmail={(e) => setEmailSearch(e.target.value)}
                    onClick={handleSearchName}
                />

                <UserTable
                    userList={userList}
                    onClickUpdate={(row) => handleOpenUpdateModal(row)}
                    onClickDelete={(row) => handleOpenDeleteModal(row)}
                />
                {userList?.length > 0 && (
                    <CustomPagination
                        totalCount={totalCount}
                        pageQueries={pageQueries}
                        setPageQueries={setPageQueries}
                        fetchList={(page) =>
                            getListUser({
                                page,
                                per_page: pageQueries.per_page,
                            })
                        }
                    />
                )}

                {openCreateModal && (
                    <CreateNewUser
                        isOpenWorker={isOpenWorker}
                        setIsOpenWorker={setIsOpenWorker}
                        setIsOpenLineBotFriend={setIsOpenLineBotFriend}
                        isOpenLineBotFriend={isOpenLineBotFriend}
                        open={openCreateModal}
                        setOpen={setOpenCreateModal}
                        onFinish={() => {
                            getListUser();
                            getLineFriendOptions();
                            setOpenCreateModal(false);
                            getWorkerOptions();
                        }}
                        lineFriendsOptions={lineFriendsOptions}
                        workerOptions={workerOptions}
                        readOnly={readOnly}
                        setReadOnly={setReadOnly}
                        isOpenRole={isOpenRole}
                        setIsOpenRole={setIsOpenRole}
                    />
                )}

                {openUpdateModal && selectedUser && (
                    <UpdateUser
                        open={openUpdateModal}
                        setOpen={setOpenUpdateModal}
                        selectedUser={selectedUser}
                        onFinish={() => {
                            getListUser();
                            getLineFriendOptions();
                            setOpenUpdateModal(false);
                            getWorkerOptions();
                        }}
                        lineFriendsOptions={lineFriendsOptions}
                        workerOptions={workerOptions}
                    />
                )}

                {openDeleteModal && selectedUser && (
                    <DeleteUser
                        open={openDeleteModal}
                        setOpen={setOpenDeleteModal}
                        onFinish={() => {
                            getListUser();
                            getLineFriendOptions();
                            setOpenDeleteModal(false);
                        }}
                        selectedUser={selectedUser}
                    />
                )}
            </div>
        </div>
    );
};

export default UserList;
